export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Our Treatment Programs</h2>
          <p>
            Is anyone of yours a victim of drug addiction? Before addiction takes away your loved ones, save them. We provide you the facility to take the drug victim from home.
          </p>
        </div>
        <div className='row' >
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className='col-md-4 col-sm-6 servicecrd'>
                {' '}
                <a href={d.url}>
                  <img src={d.img} width="100%" height="300px"></img>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </a>
              </div>
            ))
            : 'loading'}
        </div>

      </div>
    </div>
  )
}
