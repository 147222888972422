import SimpleImageSlider from "react-simple-image-slider";

const images = [
  { url: "img/crousal/robanner.jpg" },
  { url: "img/crousal/ro-plant-banner-2.webp" },
  { url: "img/nbanner2.png" },
];

const Crous = () => {
  return (
    <div id="crous" style={{marginTop:"50px"}} >
    <title>ro plant installation,Uninstallation,repair service in india.</title>
    <meta name="description" content=" we are best technician repair for all ro brand services at affordable rates all over India. "></meta>
      <SimpleImageSlider 
        width={'100%'}
        height={500}
        images={images}
        showBullets={true}
        showNavs={true}
        slideDuration={0.3}
        autoPlay={0.5}
      />
    </div>
  );
}
export default Crous;