import { useState, useEffect } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";

import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";
import Home from "./Home";
import { PageNotFound } from "./components/pageNotFound";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter"


import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { LedService } from "./components/pages/led";
import { WashingService } from "./components/pages/wasingm";
import { FridgeService } from "./components/pages/fridge";
import { ACService } from "./components/pages/ac";
import { DishwasherService } from "./components/pages/dishwasher";
import { OvenService } from "./components/pages/oven";




export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <div className='social'>
        <div>
          <a className="whatsappscreen" href="https://api.whatsapp.com/send?phone=919620119493" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phonescreen" href="tel:18004201440">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/return-policy" element={<Returnypolicy />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/faq" element={<Faqp />} />
        
        <Route path="/washingmachine-service" element={<WashingService />} />
        <Route path="/fridge-service" element={<FridgeService />} />
        <Route path="/ac-service" element={<ACService />} />
        <Route path="/dishwasher-service" element={<DishwasherService />} />
        <Route path="/led-service" element={<LedService />} />
        <Route path="/oven-service" element={<OvenService />} />
        
        
      </Routes>
      <Footerdetail data={landingPageData.Contact} />
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;
