import React from "react";

export const OvenService = (props) => {
    return (
        <div id=''>
        <div className="bnr">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        {/* Left side content or image (if needed) */}
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="hero-banner">
                            <div className="hero-content">
                                <h1>Microwave Oven</h1>
                                <h2>Repair Service</h2>
                                <a href="#services" className="hero-button">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 

            <div className="contact-container">
            <h1 className="contact-title">Get in touch</h1>
            <p className="contact-description">
              Want to get in touch? We'd love to hear from you. Here's how you can reach us...
            </p>
            <div className="contact-cards">
              <div className="contact-card">
                <div className="contact-icon">📞</div>
                <h2>Talk to Sales</h2>
                <p>Interested in our software? Just pick up the phone to chat with a member of our sales team.</p>
                <a href="tel:+35315124400" className="contact-link">+353 1 512 4400</a>
              </div>
              <div className="contact-card">
                <div className="contact-icon">💬</div>
                <h2>Contact Customer Support</h2>
                <p>Sometimes you need a little help from your friends. Or a support rep. Don't worry... we're here for you.</p>
                <a href="#" className="contact-link">Contact Support</a>
              </div>
            </div>
          </div>

           
        </div>
    )
}